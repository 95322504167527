<template>
    <!-- 表单设计 -->
    <div class="form-design-wrapper">
        <component-library
            :formItems="formItems"
            :commonComponents="commonComponentsData"
            :componentLibraryList="componentLibraryList"
            :libraryTypeId="libraryTypeId"
            :libraryList="libraryList"
            @changeActiveItem="changeActiveItem"
            @setNewDesignList="setNewDesignList"
            @changeActiveData="changeActiveData"
            @handlerClickLibraryTypeItem="handlerClickLibraryTypeItem"
            :designListClone="designNewList"
        ></component-library>
        <previewArea
            ref="designer"
            :list="designNewList"
            :activeData="activeNewData"
            @clear="designNewList = []"
            @updateJSON="handlerUpdateJSON"
            @handlerActiveItemChange="handlerActiveItemChange"
            @throwOutList="throwOutList"
            @deletedItems="deletedItems"
            @changeModifyCompConfiguration="changeModifyCompConfiguration"
            @changeDayStudentCommentContent="changeDayStudentCommentContent"
        ></previewArea>
        <component-properties
            :activeItem="activeNewData"
            :itemList="designNewList"
            :currentProperties="currentProperties"
            :selctOption="selctOption"
            @selectAssociated="selectAssociated"
            @handlerChangeAssociatedEquipment="handlerChangeAssociatedEquipment"
            @handlerChangeReasonLeaveRequired="handlerChangeReasonLeaveRequired"
            @handlerReasonRequired="handlerReasonRequired"
            @handlerAttachmentRequired="handlerAttachmentRequired"
            @allowMultipleApplicant="allowMultipleApplicant"
        ></component-properties>
        <dialog-wrapper
            :dialogObj="dialogAssociated"
            @handleClose="handleCloseAssociated"
        >
            <div>
                <p style="height: 40px; color: red">
                    *选择选项值，关联对应组件显示
                </p>
            </div>
            <div class="form-body">
                <el-form>
                    <el-form-item
                        v-for="(item, index) of associatedList"
                        :key="index"
                    >
                        <el-row>
                            <el-col :span="10">
                                <el-select
                                    v-model="item.optionValue"
                                    placeholder="请选择选项值"
                                >
                                    <el-option
                                        v-for="(item, index) of operList"
                                        :key="index"
                                        :label="item.label"
                                        :value="item.value"
                                    ></el-option>
                                    <!--  -->
                                </el-select>
                            </el-col>
                            <el-col :span="9">
                                <el-select
                                    v-model="item.component"
                                    placeholder="请选择关联组件"
                                >
                                    <el-option
                                        v-for="(item, index) of associatedComp"
                                        :key="index"
                                        :label="item.label"
                                        :value="item.id"
                                    ></el-option>
                                </el-select>
                            </el-col>
                            <el-col :span="2">
                                <span
                                    v-if="index == associatedList.length - 1"
                                    class="associatedIcon el-icon-circle-plus"
                                    @click="addAssociated(index)"
                                ></span>
                            </el-col>
                            <el-col
                                :span="2"
                                v-if="associatedList.length > 1"
                            >
                                <span
                                    class="associatedIcon el-icon-remove"
                                    @click="delAssociated(index)"
                                ></span>
                            </el-col>
                        </el-row>
                    </el-form-item>
                </el-form>
                <div>
                    <el-button
                        type="primary"
                        @click="submitAssociated"
                        >保存</el-button
                    >
                    <el-button @click="cancellationAssociated">取消</el-button>
                </div>
            </div>
        </dialog-wrapper>
    </div>
</template>
<script>
import {DialogWrapper} from "common-local";
/*
    1. ComponentLibrary 组件库
        PreviewArea 预览区
        ComponentProperties 组件属性
*/
import ComponentLibrary from "./FormDesign/ComponentLibrary.vue";
import PreviewArea from "./FormDesign/PreviewArea.vue";
import ComponentProperties from "./FormDesign/ComponentProperties.vue";
// 生成随机
import {getSimpleId} from "@/libs/utils.js";
/*
    2. 静态数据
*/
import {
    formItems,
    commonComponents,
    componentLibraryData,
} from "@/assets/data/formDesign/compentsList2.js";
import {departureTime} from "@/assets/data/formDesign/comps/departureTime.js";
import {returnTime} from "@/assets/data/formDesign/comps/returnTime.js";
export default {
    name: "FormDesign",
    components: {
        ComponentLibrary,
        PreviewArea,
        ComponentProperties,
        DialogWrapper,
    },
    props: {
        designNewListParent: Array,
    },
    data() {
        return {
            formItems,
            commonComponents,
            componentLibraryData,
            departureTime,
            returnTime,
            dialogAssociated: {
                title: "选项关联",
                dialogVisible: false,
                width: "600px",
            },
            associatedList: [
                {
                    optionValue: "",
                    component: "",
                },
            ],
            operList: [],
            screeningKeyList: [
                "applicant",
                "pickDropPersons",
                "entryExitSelect",
                "approvalFormSelect",
                "departureTime",
                "returnTime",
            ],
            associatedComp: [],
            commonComponentsData: [],
            designNewList: [],
            componentLibraryList: [],
            activeNewData: {},
            selfData: {},
            /**
             * 库切换
             * */
            libraryTypeId: "componentLibrary",
            libraryList: [
                {
                    id: "componentLibrary",
                    title: "组件库",
                },
            ],
            currentProperties: "组件属性",
            selctOption: "",
        };
    },
    created() {
        this.init();
    },
    methods: {
        init() {
            this.$eventBus.$on("transmission", (obj) => {
                let {type, data, comName} = obj;
                if (type == "active") {
                    this.selctOption = data;
                }
                if (type == "copy") {
                    this.designNewList.forEach((item, index) => {
                        if (item.key == comName) {
                            this.$set(this.designNewList[index], "children", data);
                        }
                    });
                }
                if (type == "delete") {
                    this.designNewList.forEach((item, index) => {
                        if (item.key == comName) {
                            this.$set(this.designNewList[index], "children", data);
                        }
                    });
                }
            });
            this.initData();
            this.$forceUpdate();
        },
        initData() {
            this.commonComponentsData = JSON.parse(
                JSON.stringify(this.commonComponents),
            );
            console.debug("11111");
            if (this.designNewListParent) {
                this.designNewList = JSON.parse(
                    JSON.stringify(this.designNewListParent),
                );
                this.designNewList.forEach((item) => {
                    if (item.compType == "applicantStu") {
                        item.key = "applicantStu";
                    }
                });
            }
            this.componentLibraryList = JSON.parse(
                JSON.stringify(this.componentLibraryData),
            );
            this.commonComponentsData.forEach((item) => {
                this.designNewList.forEach((subItem) => {
                    if (item.key == subItem.key) {
                        this.$set(item, "draggable", false);
                    }
                });
            });

            this.componentLibraryList.forEach((item) => {
                this.$set(item, "draggable", true);
            });

            // 处理日期组件年月日时分秒显示问题
            const dateTimeFormat = {
                datetime: "yyyy-MM-dd HH:mm:ss",
                daterange: "yyyy-MM-dd",
            };

            const dateTimeType = {
                datetime: "datetime",
                daterange: "date",
            };

            this.designNewList.forEach((item) => {
                if (item.compType === "dateTime") {
                    this.$set(item, "type", dateTimeType[item.status]);
                    item.format = dateTimeFormat[item.status];
                    item["value-format"] = dateTimeFormat[item.status];
                }
            });
        },
        // 添加组件
        changeActiveItem(data) {
            this.$refs.designer.activeItem = data;
        },
        /**
         * @Description: 拖拽结束触发函数
         * @DoWhat: 拖拽结束后操作
         * @UseScenarios:
         * @Attention: 无
         * @Author: 武东超
         * @Date: 2023-03-21 11:06:28
         * @param {*} data
         * @param {*} type
         */
        setNewDesignList(data, type) {
            console.debug(JSON.parse(JSON.stringify(data), type));
            this.designNewList = data;
            this.checkHasKeys(this.designNewList);
            this.$emit("throwOutList", this.designNewList);
            this.controlDisplay(data);
            // 点击添加自动滚动到底部
            if (type === "click") {
                this.$nextTick(() => {
                    this.smoothScrollToBottom();
                });
            }
        },
        smoothScrollToBottom() {
            const contentElement = this.$refs.designer.$refs.areaWrapper;
            const startScrollTop = contentElement.scrollTop;
            const endScrollTop =
                contentElement.scrollHeight - contentElement.clientHeight;
            const duration = 500; // 滚动动画的总持续时间（毫秒）
            const startTime = performance.now();
            // 缓动函数
            const easeOutCubic = (t) => {
                return 1 - Math.pow(1 - t, 3);
            };
            const scrollAnimation = (timestamp) => {
                const currentTime = timestamp - startTime;
                const scrollAmount = endScrollTop - startScrollTop;
                const progress = Math.min(currentTime / duration, 1);
                const easedProgress = easeOutCubic(progress);
                contentElement.scrollTop =
                    startScrollTop + scrollAmount * easedProgress;
                if (currentTime < duration) {
                    requestAnimationFrame(scrollAnimation);
                }
            };
            requestAnimationFrame(scrollAnimation);
        },
        changeActiveData(data) {
            this.activeNewData = data;
        },
        changeDayStudentCommentContent(e) {
            this.activeNewData.dayStudentComment = e;
        },
        /**
         * @Description: 特殊规则判断
         * @DoWhat: 【请假/调休套件】关联门禁打开后则不允许添加【出入类型】，如果包含出入类型则不能打开关联门禁
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-05-04 15:40:57
         */
        specialRulesJudgment(data) {
            let key = "kitsLeaveAbsence",
                contrastKey = "entryExitSelect",
                specialItem = data.filter((item) => item.key == key)[0],
                contrastItem = data.filter(
                    (item) => item.key == contrastKey,
                )[0];

            if (
                specialItem &&
                Object.keys(specialItem).length > 0 &&
                contrastItem &&
                Object.keys(contrastItem).length > 0 &&
                specialItem.isInSetting
            ) {
                this.$set(specialItem, "isInSetting", 0);
                this.$message.info(
                    "表单中已存在出入类型组件，故请假套件已取消关联门禁",
                );
            }
        },
        /**
         * @Description: 切换组件库
         * @DoWhat: 切换组件库, 并且把属性调整为对应名称
         * @UseScenarios: 切换组件库
         * @Attention: 切换组件库
         * @Author: 武东超
         * @Date: 2023-03-20 11:33:53
         * @param {*} json
         */
        handlerClickLibraryTypeItem(data) {
            this.controlDisplay(this.designNewList);
            console.log(data, "--", this.designNewList);
            this.libraryTypeId = data.id;
            console.log(this.libraryTypeId, "this.libraryTypeId");
            switch (this.libraryTypeId) {
                case "componentLibrary":
                    this.currentProperties = "组件属性";
                    break;
                case "kitLibrary":
                    this.currentProperties = "套件属性";
                    break;
                default:
                    this.currentProperties = "组件属性";
                    break;
            }
        },
        handlerUpdateJSON(json) {
            const jsonObject = JSON.parse(json);
            this.designNewList = [];
            this.designNewList = this.designNewList.concat(jsonObject.list);
        },
        // 切换选中
        handlerActiveItemChange(obj) {
            if (obj.key == "EvaluateTeachers") {
                obj.selecItem = this.selctOption;
            }
            if (obj.key == "handlerActiveItemChange") {
                obj.selecItem = this.selctOption;
            }
            console.debug("切换选中", JSON.parse(JSON.stringify(obj)));
            this.activeNewData = obj;
            let changeTitle = {
                id: "",
            };
            if (["kitsLeaveAbsence", "kitsShiftChange"].includes(obj.key)) {
                changeTitle.id = "kitLibrary";
            } else {
                changeTitle.id = "componentLibrary";
            }
            this.handlerClickLibraryTypeItem(changeTitle);
        },
        // 抛出最终list
        // 控制固定组件只能拖拽一次
        throwOutList(data) {
            this.checkHasKeys(data);
            this.$emit("throwOutList", data);
            this.controlDisplay(data);
        },
        /**
         * @Description: 删除后判断是否未固定组件及套件，如果是则可重新拖拽
         * @DoWhat: 删除后判断是否未固定组件及套件，如果是则可重新拖拽
         * @UseScenarios: 删除后判断是否未固定组件及套件，如果是则可重新拖拽
         * @Attention: 无
         * @Author: 武东超
         * @Date: 2023-03-21 11:50:42
         * @param {*} data
         */
        deletedItems(data) {
            [data].forEach((item) => {
                this.componentLibraryList.forEach((subItem) => {
                    if (item.key == subItem.key) {
                        this.$set(subItem, "draggable", true);
                    }
                });

                this.commonComponentsData.forEach((subItem) => {
                    if (item.key == subItem.key) {
                        this.$set(subItem, "draggable", true);
                    }
                });
            });
        },
        /*
            checkHasKeys/ 检测是否有出入校
            putinDate/ 有出入校类型则加入出入校时间段
            deleteDate/ 否则删除出入校时间段
        */
        checkHasKeys(data) {
            let boolean = false,
                compTypeStatus = false;
            data.forEach((item) => {
                if (item.key == "entryExitSelect") {
                    boolean = true;
                }
            });

            data.forEach((item) => {
                if (item.key == "departureTime") {
                    compTypeStatus = true;
                }
            });

            if (!compTypeStatus && boolean) {
                this.putinDate();
            }

            if (!boolean) {
                this.deleteDate();
            }
        },
        putinDate() {
            this.departureTime.id = "fd_" + getSimpleId();
            this.departureTime._id = this.departureTime.id;
            this.returnTime.id = "fd_" + getSimpleId();
            this.returnTime._id = this.returnTime.id;

            this.designNewList.push(this.departureTime, this.returnTime);
        },
        deleteDate() {
            this.designNewList.forEach((item, index) => {
                if (item.key == "departureTime") {
                    this.designNewList.splice(index, 1);
                }
            });

            this.designNewList.forEach((item, index) => {
                if (item.key == "returnTime") {
                    this.designNewList.splice(index, 1);
                }
            });
        },
        /**
         * @Description: 固定组件/套件库 只能拖拽一次
         * @DoWhat: 判断是否为固定组件/套件库
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-03-21 10:37:49
         * @param {*} data
         */
        controlDisplay(data) {
            this.commonComponentsData.forEach((subItem) => {
                this.$set(subItem, "draggable", true);
            });
            this.componentLibraryList.forEach((subItem) => {
                this.$set(subItem, "draggable", true);
            });

            data.forEach((item) => {
                this.componentLibraryList.forEach((subItem) => {
                    if (item.key == subItem.key) {
                        this.$set(subItem, "draggable", false);
                    }
                });

                this.commonComponentsData.forEach((subItem) => {
                    if (item.key == subItem.key) {
                        this.$set(subItem, "draggable", false);
                    }
                });
            });

            this.specialRulesJudgment(this.designNewList);
        },
        /*
            *选项关联相关
            *弹窗相关
            selectAssociated/ 打开选项关联
            handleCloseAssociated/ 关闭选项关联
            handlerChangeAssociatedEquipment/ 关联门禁
            handlerChangeReasonLeaveRequired/ 请假事由是否必填
            handlerReasonRequired / 申请原因是否必填
            handlerAttachmentRequired / 附件是否必须上传
            allowMultipleApplicant / 是否允许多个申请人
        */
        selectAssociated(data) {
            this.selfData = data;
            this.resetAssociationsData();
            data.options.forEach((item) => {
                if (!item.label || !item.value) {
                    this.$message.error("下拉选项未填写完整");
                    return;
                }
            });
            this.operList = JSON.parse(JSON.stringify(data.options));

            this.associatedComp = this.designNewList.filter((item) => {
                return !this.screeningKeyList.some((subItem) => {
                    return item.key == subItem;
                });
            });
            // 删除自身相关
            this.associatedComp.forEach((item, index) => {
                if (item.id == data.id) {
                    this.associatedComp.splice(index, 1);
                }
            });
            // 是否可以打开选项关联
            this.dialogAssociated.dialogVisible =
                !this.dialogAssociated.dialogVisible;
        },
        handleCloseAssociated() {
            this.dialogAssociated.dialogVisible =
                !this.dialogAssociated.dialogVisible;
        },
        handlerChangeAssociatedEquipment(type) {
            this.specialRulesJudgment(this.designNewList);
        },
        handlerChangeReasonLeaveRequired(type) {},
        handlerReasonRequired(type) {
            console.log("handlerReasonRequired", type, "----");
        },
        handlerAttachmentRequired() {},
        allowMultipleApplicant() {},
        /*
            *   选项关联相关
            *
            *   submitAssociated/ 提交
                cancellationAssociated/ 关闭
        */
        submitAssociated() {
            let finishTyps = false,
                associatedObj = {},
                associatedArr = [],
                associateList = [],
                compList = [];
            this.associatedList.forEach((item) => {
                if (!item.optionValue || !item.component) {
                    finishTyps = true;
                }
            });

            if (finishTyps) {
                this.$message.error("选项未填写完整");
                return;
            } else {
                this.associatedList.forEach((item) => {
                    compList.push(item.component);
                    if (!associatedObj.hasOwnProperty(item.optionValue)) {
                        associatedObj[item.optionValue] = item.optionValue;
                    }
                });

                Object.keys(associatedObj).forEach((key) => {
                    associateList.push(key);
                    let obj = {
                        key: key,
                        list: [],
                    };
                    this.associatedList.forEach((item) => {
                        if (item.optionValue == key) {
                            obj.list.push(item.component);
                        }
                    });
                    associatedArr.push(obj);
                });

                // // 合并每一项的list且去重
                // associatedArr.forEach((item) => {

                // })
                // 查找组件本身且已经关联的所有组件，并修改配置为【已配置关联】
                // affiliateStatus状态修改为false
                // hideStatus 修改为false(非关联组件)
                // disabledType 为true
                // 隐藏所有关联的组件

                this.designNewList.forEach((item) => {
                    if (item.id == this.selfData.id) {
                        item.associatedData = associatedArr;
                        item.associateList = associateList;
                        item.compList = compList;
                        item.affiliateStatus = false;
                        item.disabledType = true;
                    }
                    this.associatedList.forEach((subItem) => {
                        if (item.id == subItem.component) {
                            item.hideStatus = false;
                            item.affiliateStatus = false;
                            item.disabledType = true;
                        }
                    });
                });
            }
        },
        /*
            获取下拉的值
        */
        changeModifyCompConfiguration(comp) {
            const comList = JSON.parse(JSON.stringify(comp.compList));
            let requObj = {},
                comsList = [],
                assList = [];
            comList.forEach((item) => {
                if (!requObj.hasOwnProperty(item)) {
                    requObj[item] = item;
                    comsList.push(item);
                }
            });

            assList = this.designNewList.filter((item) => {
                return comsList.some((c) => {
                    return c == item.id;
                });
            });

            if (!comp.associateList) return;
            let affiliateStatus = comp.associateList.indexOf(
                comp.value.toString(),
            );
            if (affiliateStatus != "-1") {
                comp.associatedData.forEach((item) => {
                    if (item.key == comp.value) {
                        assList.forEach((subItem) => {
                            item.list.forEach((enItem) => {
                                if (subItem.id == enItem) {
                                    subItem.hideStatus = true;
                                } else {
                                    subItem.hideStatus = false;
                                }
                            });
                        });
                    }
                });
            } else {
            }
        },

        cancellationAssociated() {},
        /*
         * 关联选项相关
         * 重置数据
         */
        resetAssociationsData() {
            this.associatedList = [
                {
                    optionValue: "",
                    component: "",
                },
            ];
            this.associatedComp = [];
        },
        /*
            * 关联选项
            addAssociated/ 增加
            delAssociated/ 删除
        */
        addAssociated() {
            this.associatedList.push({
                optionValue: "",
                component: "",
            });
        },
        delAssociated(idx) {
            this.associatedList.splice(idx, 1);
        },
    },
    watch: {
        commonComponentsData: {
            handler(newVal) {},
            deep: true,
        },
        designNewList: {
            handler(newVal) {},
            deep: true,
        },
        componentLibraryList: {
            handler(newVal) {},
            deep: true,
        },
        commonComponentsData: {
            handler(newVal) {},
            deep: true,
        },
    },
    beforeDestroy() {
        this.$eventBus.$off("transmission");
    },
};
</script>

<style lang="scss" scoped>
.form-design-wrapper {
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    background-color: #f7f7f7;
    height: 100%;
    min-width: 1300px;
    padding-top: 10px;
}

.form-body {
    width: 90%;
    margin: 0 auto;
}

.associatedIcon {
    font-size: 20px;
    cursor: pointer;
    margin: 7px;
    color: #1989fa;
}
</style>
